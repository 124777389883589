import { InputChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppInputCustomEvent } from '@platform-ui-kit/components-library/loader'
import {
  WppActionButton,
  WppButton,
  WppCard,
  WppEmptyFolder,
  WppFilterButton,
  WppIconDatabase,
  WppIconDocument,
  WppIconDownload,
  WppIconFileZip,
  WppIconImage,
  WppIconLink,
  WppIconMore,
  WppIconMusic,
  WppIconPin,
  WppIconPitch,
  WppIconPlus,
  WppIconStatisticDocument,
  WppIconTrash,
  WppIconVideoClip,
  WppInput,
  WppListItem,
  WppMenuContext,
  WppTag,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { RowClickedEvent } from 'ag-grid-community'
import clsx from 'clsx'
import { format } from 'date-fns'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useSearchParams } from 'react-router-dom'

import { useFetchProjectListApi } from 'api/projects/queryFetchers/useFetchProjectFilesApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, tableActions, TablePageInfinite, TableInfiniteLoader } from 'components/common/table'
import { PageBackToTop } from 'components/common/table/PageBackToTop'
import { TableKey } from 'constants/table'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useFileAnalytics } from 'hooks/useFileAnalytics'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useProject } from 'hooks/useProject'
import { useStableCallback } from 'hooks/useStableCallback'
import { getAppliedFilters } from 'pages/dashboard/components/utils'
import { showFileDeleteModal } from 'pages/project/components/files/components/fileDeleteModal/FileDeleteModal'
import { showFilesFiltersModal } from 'pages/project/components/files/components/filesFilters/FilesFiltersModal'
import { showUploadFilesModal } from 'pages/project/components/files/components/uploadFilesModal/UploadFilesModal'
import styles from 'pages/project/components/files/Files.module.scss'
import {
  FileFormatMapByCategory,
  FilesColorFormat,
  FilesFormat,
  FileTypes,
  PIN_LIMIT,
} from 'pages/project/components/files/utils'
import { CopyType, useCopyUrlToClipboard } from 'pages/project/hooks/useCopyUrlToClipboard'
import { useDownloadProjectFile } from 'pages/project/hooks/useDownloadProjectFile'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { useTogglePinFile } from 'pages/project/hooks/useTogglePinFile'
import { LocalStorageKey } from 'types/common/localStorage'
import { DetailsModalType } from 'types/common/utils'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { FilesDTO, FilesFilter } from 'types/projects/Files'
import { ProcessType } from 'types/projects/projects'
import { fullName, isEqualEmails } from 'utils/common'
import { DATE_FORMAT } from 'utils/dateFormat'
import { hasClosestInteractiveElement } from 'utils/dom'
import { formatBytes } from 'utils/files'

const FilesIconFormat: Record<FileTypes, ReactNode> = {
  music: <WppIconMusic />,
  compressed: <WppIconFileZip />,
  data: <WppIconDatabase />,
  images: <WppIconImage />,
  presentations: <WppIconPitch />,
  spreadsheet: <WppIconStatisticDocument />,
  videos: <WppIconVideoClip />,
  documents: <WppIconDocument />,
}

const gridOptions = {
  rowStyle: { cursor: 'pointer' },
}

export const initialFilesFilters: FilesFilter = {
  extensions: [],
  uploadedBy: [],
  search: '',
  pinned: false,
}

export const Files = () => {
  const { t } = useTranslation()
  const {
    osContext: { userDetails },
  } = useOs()
  const { project, isInactive } = useProject()
  const handleFilesList = useFetchProjectListApi({ staleTime: 60 * 1000 })

  const [, setSearchParams] = useSearchParams()

  const { trackViewProjectFiles, trackFileDownload } = useFileAnalytics(project)

  const { copyToClipboardAction } = useCopyUrlToClipboard(CopyType.FILE)
  const { downloadFileAction } = useDownloadProjectFile()
  const { togglePinFile } = useTogglePinFile()

  const [filesFilters, setFilesFilters] = useLocalStorage<FilesFilter>(
    `${LocalStorageKey.PROJECT_FILES_FILTERS}:${project.id}:${userDetails.id}`,
    {} as FilesFilter,
  )
  const [filters, setFilters] = useState<FilesFilter>({ ...initialFilesFilters, ...filesFilters })
  const [isEmpty, setIsEmpty] = useState(false)

  const [pinnedCount, setPinnedCount] = useState(0)

  useEffect(() => {
    const { search, ...rest } = filters
    setFilesFilters(rest)
  }, [setFilesFilters, filters])

  const { isPermitted } = useIsPermitted()
  const { hasRole, isResponsible } = useHasProjectRole()
  const isOwner = hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const setSearchDebounced = useDebounceFn((e: WppInputCustomEvent<InputChangeEventDetail>) => {
    const search = e.detail.value || ''
    const searchQuery = search.trim().length >= 2 ? search.trim() : undefined
    setFilters(filters => ({ ...filters, search: searchQuery }))
  }, 300)

  const filtersCounts = useMemo(() => getAppliedFilters(filters), [filters])

  useEffect(() => {
    trackViewProjectFiles({ filtersApplied: filtersCounts > 1, searchApplied: !!filters.search })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCloseFilterModal = (newFilter: FilesFilter) => {
    setFilters(filters => ({ ...filters, ...newFilter }))
  }

  const loader: TableInfiniteLoader<FilesDTO> = useCallback(
    async ({ endRow, startRow }) => {
      const itemsPerPage = endRow - startRow
      const getAllExtensionsFromFileKey = filters.extensions
        .map((type: keyof typeof FilesFormat) => FilesFormat[type] || '')
        .flat()

      const searchQuery = filters.search
        ? filters.search.trim().length >= 2
          ? filters.search?.trim()
          : undefined
        : undefined

      const {
        data: { data, paginator, pinnedFilesCount },
      } = await handleFilesList({
        projectId: project.id,
        itemsPerPage,
        page: endRow / itemsPerPage,
        search: searchQuery,
        extensions: getAllExtensionsFromFileKey,
        uploadedBy: filters.uploadedBy.map(user => user.email),
        pinned: filters.pinned,
      })

      const isFirstPage = startRow === 0

      if (isFirstPage) {
        setPinnedCount(pinnedFilesCount)
      }

      // `totalPages === 0` means that there is no data at all
      if (paginator.totalPages > 0 && paginator.page > paginator.totalPages) {
        throw new Error('An extra page was requested')
      }

      return {
        data,
        totalRowsCount: paginator.totalItems,
      }
    },
    [filters, handleFilesList, project.id],
  )

  const pinLimit = pinnedCount >= PIN_LIMIT

  const openFilePreview = useCallback(
    (id: string) => setSearchParams({ view: DetailsModalType.FILE_DETAILS_PREVIEW, id }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const columnDefs = useMemo<ColDef<FilesDTO>[]>(
    () => [
      {
        colId: 'pin',
        width: 36,
        resizable: false,
        cellStyle: { paddingRight: 0 },
        hide: !pinnedCount,
        cellRenderer: ({ data }) => {
          return data?.pinnedAt ? (
            <WppTooltip text={t('project.files.table.pinned')}>
              <WppIconPin className={styles.pinIcon} />
            </WppTooltip>
          ) : null
        },
      },
      {
        colId: 'name',
        flex: 2.5,
        minWidth: 250,
        headerName: t('project.files.table.file_name')!,
        cellRenderer: ({ data }) => {
          const name = (data?.name || '').replace(/\.[^/.]+$/, '')
          const extension = data?.name.match(/\.[^.]+$/)![0] || data?.fileType!
          const Icon = FilesIconFormat[FileFormatMapByCategory[extension]!]

          const textType = data?.pinnedAt ? 's-strong' : 's-body'
          return (
            <Flex gap={8} className={styles.overflow}>
              {Icon}
              <WppTypography type={textType} data-testid="table-file-name" className={styles.overflow}>
                {name}
              </WppTypography>
            </Flex>
          )
        },
      },
      {
        colId: 'fileType',
        flex: 1,
        minWidth: 100,
        headerName: t('project.files.table.file_type')!,
        cellRenderer: ({ data }) => {
          const extension = data?.name.match(/\.[^.]+$/)![0] || data?.fileType!
          const colorIdx = FilesColorFormat[FileFormatMapByCategory[extension]!] || 9

          return (
            <WppTag label={extension.substring(1)} categoricalColorIndex={colorIdx} data-testid="table-file-type" />
          )
        },
      },
      {
        colId: 'size',
        flex: 1,
        minWidth: 100,
        headerName: t('project.files.table.file_size')!,
        cellRenderer: ({ data }) => (
          <WppTypography type="s-body" data-testid="table-file-size" className={styles.overflow}>
            {formatBytes(data?.size || 0)}
          </WppTypography>
        ),
      },
      {
        colId: 'createdBy',
        flex: 1.75,
        minWidth: 175,
        headerName: t('project.files.table.uploaded_by')!,
        cellRenderer: ({ data }) => (
          <Flex align="center" gap={16} className={styles.overflow}>
            <Flex>
              <Avatar
                className={styles.noShrink}
                size="xs"
                name={fullName(data?.createdBy.firstname, data?.createdBy.lastname)}
                src={data?.createdBy?.avatarUrl ?? ''}
              />
            </Flex>
            <WppTypography
              type="s-body"
              title={fullName(data?.createdBy?.firstname, data?.createdBy?.lastname)}
              data-testid="table-file-createdBy"
              className={styles.overflow}
            >
              {fullName(data?.createdBy?.firstname, data?.createdBy?.lastname)}
            </WppTypography>
          </Flex>
        ),
      },
      {
        colId: 'createdAt',
        flex: 1.25,
        minWidth: 125,
        headerName: t('project.files.table.date_uploaded')!,
        cellRenderer: ({ data }) => (
          <WppTypography type="s-body" data-testid="table-file-createdAt" className={styles.overflow}>
            {format(new Date(data!.createdAt), DATE_FORMAT.DD_MM_YYYY)!}
          </WppTypography>
        ),
      },
      {
        width: 64,
        minWidth: 64,
        resizable: false,
        pinned: 'right',
        colId: 'actions',
        cellRenderer: ({ data }) => (
          <WppMenuContext
            className={styles.rowContextMenu}
            slot="actions"
            dropdownConfig={{
              appendTo: () => document.body,
              placement: 'bottom-end',
            }}
          >
            <WppActionButton slot="trigger-element" variant="secondary">
              <WppIconMore slot="icon-start" direction="horizontal" />
            </WppActionButton>
            <Flex direction="column" gap={4}>
              <WppListItem
                onWppChangeListItem={() => openFilePreview(data!.id)}
                data-testid="file-details-context-action"
              >
                <WppIconDocument slot="left" />
                <WppTypography slot="label" type="s-body">
                  {t('project.files.view_details_action')}
                </WppTypography>
              </WppListItem>
              <WppListItem
                data-testid="copy-file-link-context-action"
                onWppChangeListItem={() =>
                  copyToClipboardAction(
                    `?${createSearchParams({
                      view: DetailsModalType.FILE_DETAILS_PREVIEW,
                      id: data!.id,
                    })}`,
                  )
                }
              >
                <WppIconLink slot="left" />
                <WppTypography slot="label" type="s-body">
                  {t('project.files.copy_link_action')}
                </WppTypography>
              </WppListItem>
              <WppListItem
                data-testid="download-file-context-action"
                onWppChangeListItem={() => {
                  const extension = data?.name.match(/\.[^.]+$/)![0] || data?.fileType!
                  downloadFileAction(data!.key)
                  trackFileDownload({
                    fileExtension: extension,
                    fileSize: formatBytes(data?.size!),
                  })
                }}
              >
                <WppIconDownload slot="left" />
                <WppTypography slot="label" type="s-body">
                  {t('project.files.download_action')}
                </WppTypography>
              </WppListItem>

              {isOwner && !data?.pinnedAt && (
                <WppTooltip
                  className={styles.pinTooltip}
                  config={{ trigger: pinLimit ? 'mouseenter' : 'manual' }}
                  header={t('project.files.pin.pin_tooltip_title')}
                  text={t('project.files.pin.pin_tooltip_text')}
                >
                  <WppListItem
                    onWppChangeListItem={() => togglePinFile(data!)}
                    disabled={pinLimit}
                    data-testid="file-details-pin-action"
                  >
                    <WppIconPin slot="left" />
                    <span slot="label">{t('project.files.pin_action')}</span>
                  </WppListItem>
                </WppTooltip>
              )}

              {isOwner && data?.pinnedAt && (
                <WppListItem onWppChangeListItem={() => togglePinFile(data!)} data-testid="file-details-unpin-action">
                  <WppIconPin slot="left" />
                  <WppTypography slot="label" type="s-body">
                    {t('project.files.unpin_action')}
                  </WppTypography>
                </WppListItem>
              )}
              {(isEqualEmails(data?.createdBy.email, userDetails.email) || isOwner) && !isInactive && (
                <WppListItem
                  onWppChangeListItem={() =>
                    showFileDeleteModal({
                      fileId: data!.id,
                      isInFluid: project.processType === ProcessType.FLUID,
                      onDelete: () => tableActions.reload([TableKey.PROJECT_FILE_LIST]),
                    })
                  }
                  data-testid="delete-group-context-action"
                >
                  <WppIconTrash slot="left" />
                  <WppTypography slot="label" type="s-body">
                    {t('common.btn_delete')}
                  </WppTypography>
                </WppListItem>
              )}
            </Flex>
          </WppMenuContext>
        ),
      },
    ],
    [
      pinnedCount,
      t,
      isOwner,
      pinLimit,
      userDetails.email,
      isInactive,
      openFilePreview,
      copyToClipboardAction,
      downloadFileAction,
      trackFileDownload,
      togglePinFile,
      project.processType,
    ],
  )

  const handleOnRowClicked = useStableCallback(({ event, data }: RowClickedEvent<FilesDTO>) => {
    const target = event?.target as HTMLElement
    if (data && !hasClosestInteractiveElement(target)) openFilePreview(data!.id)
  })

  const noRowsOverlayComponent = useCallback(() => {
    return (
      <EmptyState
        title={isEmpty ? t('project.files.no_records') : t('common.no_search_results')}
        filtersApplied={!isEmpty}
        description={isEmpty ? t('project.files.no_records_description') : t('common.no_results_description')}
        testToken="files"
        noRecordsIcon={<WppEmptyFolder width={120} />}
      >
        {isEmpty && (isOwner || isResponsible()) && (
          <WppButton size="m" onClick={() => showUploadFilesModal({ project })} disabled={isInactive}>
            <WppIconPlus slot="icon-start" />
            {t('project.files.add_file')!}
          </WppButton>
        )}
      </EmptyState>
    )
  }, [isEmpty, isInactive, isOwner, isResponsible, project, t])

  return (
    <WppCard className={clsx(styles.container, { [styles.emptyCard]: isEmpty })}>
      <PageBackToTop />
      <Flex direction="column" gap={12} className={styles.tableWrapper}>
        {!isEmpty && (
          <Flex justify="between">
            <Flex gap={12}>
              <WppInput
                size="s"
                name="search"
                placeholder={t('project.files.field_search_placeholder')!}
                onWppChange={setSearchDebounced}
                type="search"
                data-testid="project-files-search"
                className={styles.searchInput}
              />

              <WppFilterButton
                onClick={() =>
                  showFilesFiltersModal({
                    filters,
                    onFiltersSave: handleCloseFilterModal,
                  })
                }
                data-testid="files-filter-button"
                counter={filtersCounts}
              >
                {t('project.files.btn_filter')!}
              </WppFilterButton>
            </Flex>
            {(isOwner || isResponsible()) && !isEmpty && (
              <WppButton size="s" onClick={() => showUploadFilesModal({ project })} disabled={isInactive}>
                <WppIconPlus slot="icon-start" />
                {t('project.files.add_file')!}
              </WppButton>
            )}
          </Flex>
        )}

        <TablePageInfinite
          tableKey={TableKey.PROJECT_FILE_LIST}
          onRowClicked={handleOnRowClicked}
          gridOptions={gridOptions}
          loader={loader}
          rowHeight={48}
          columnDefs={columnDefs}
          suppressPaginationPanel
          onLoadSuccess={({ isEmptySource }) => {
            setIsEmpty(isEmptySource && !filters?.search && !filtersCounts)
          }}
          headerHeight={isEmpty ? 0 : undefined}
          noRowsOverlayComponent={noRowsOverlayComponent}
          className={clsx({ [styles.hideBorder]: isEmpty })}
        />
      </Flex>
    </WppCard>
  )
}
