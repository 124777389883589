import {
  WppActionButton,
  WppCard,
  WppDivider,
  WppIconExternalLink,
  WppIconEyeOn,
  WppIconMore,
  WppIconEdit,
  WppIconPlusCircle,
  WppIconTrash,
  WppListItem,
  WppMenuContext,
  WppTag,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { TenantType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { UserInfo } from 'components/common/userInfo/UserInfo'
import { useTemplateRole } from 'hooks/useTemplateRole'
import { PhasesIndicator } from 'pages/components/phasesIndicator/PhasesIndicator'
import styles from 'pages/components/templateCard/TemplateCard.module.scss'
import { TemplateStatusTag } from 'pages/template/components/templateStatusTag/TemplateStatusTag'
import { ProcessType } from 'types/projects/projects'
import { Template, TemplateState } from 'types/projects/template'
import { routesManager } from 'utils/routesManager'

type Props = {
  template: Template
  withPreview?: boolean
  withContextMenu?: boolean
  handleCreateFromTemplate?(): void
  handleRemoveTemplate?(): void
  handleTemplateEdit?(): void
} & JSX.IntrinsicElements['div']

export const TEMPLATE_PREVIEW_CLASS = 'templateCardPreview'

export const TemplateCard: FC<Props> = ({
  template,
  withPreview,
  withContextMenu,
  handleTemplateEdit,
  handleCreateFromTemplate,
  handleRemoveTemplate,
  className,
}: Props) => {
  const { t } = useTranslation()

  const [, setParams] = useSearchParams()
  const {
    osContext: { tenant },
  } = useOs()

  const { isOwnerOrGlobalManage } = useTemplateRole(template)

  const { osContext } = useOs()
  const isMultiTenant = osContext.tenant.tenantType === TenantType.Agency
  const fromCurrentTenant = tenant.id === template.tenant?.id

  const cardStats = useMemo(() => {
    return {
      phasesCount: template.phasesStats.length,
      phaseItemsCount: template.phasesStats.reduce((acc, { appsCount }) => acc + appsCount, 0),
    }
  }, [template])

  const getTagText = (tags: string[]) => {
    if (tags.length === 1) return tags[0]

    return `${tags[0]}, +${tags.length - 1}`
  }

  const handleOpenTemplate = () => {
    if (isMultiTenant && !fromCurrentTenant) {
      window.open(
        `${template.tenant?.homeUrl}/orchestration${routesManager.templates.root.getURL()}?view=template&id=${template!
          .id!}`,
        '_blank',
      )

      return
    }

    setParams({ view: 'template', id: template.id })
  }

  return (
    <WppCard
      className={clsx(styles.templateCard, className)}
      value={template.id}
      interactive={!!handleOpenTemplate}
      data-testid={`template-card-${template.id}`}
    >
      <Flex className={styles.cardBody} direction="column" onClick={handleOpenTemplate}>
        <Flex gap={8} className={styles.phaseInfo}>
          {template.templateState === TemplateState.PUBLISHED ? (
            !!template.phasesStats.length && (
              <PhasesIndicator stats={template.phasesStats} processType={template.processType} />
            )
          ) : (
            <TemplateStatusTag templateState={template.templateState || TemplateState.DRAFT} />
          )}

          {template.templateState === TemplateState.PUBLISHED && (
            <WppTypography type="xs-body" data-testid="template-phases-stats">
              {template.processType === ProcessType.LINEAR
                ? t('templates.card.stats', { ...cardStats })
                : t('templates.card.stats_fluid', { count: cardStats.phaseItemsCount })}
            </WppTypography>
          )}
        </Flex>

        <Flex direction="column" gap={4} className={styles.cardInfoWrapper}>
          <Truncate lines={2} type="l-strong" data-testid="template-name">
            {template.name}
          </Truncate>

          {template.description && (
            <Truncate type="s-body" lines={2} className={styles.description} data-testid="template-description">
              {template.description}
            </Truncate>
          )}

          {isMultiTenant && (
            <Flex gap={4} align="center">
              <WppTypography type="s-body" className={styles.projectTenantName}>
                {template.tenant?.name}
              </WppTypography>
              {!fromCurrentTenant && (
                <WppIconExternalLink height={16} width={16} className={styles.projectTenantNameIcon} />
              )}
            </Flex>
          )}
        </Flex>

        <WppDivider className={styles.divider} />
        <UserInfo
          className={styles.footer}
          user={template.createdBy}
          avatarUrl={template.createdBy?.avatarUrl}
          templateView={withPreview}
        >
          {withPreview ? (
            <WppActionButton
              className={clsx(TEMPLATE_PREVIEW_CLASS, styles.btnPreview)}
              data-testid="template-preview-button"
            >
              {t('common.btn_preview')}
            </WppActionButton>
          ) : (
            <>
              {!!template.tags?.length && (
                <WppTooltip
                  text={template.tags.join(', ')}
                  config={{ trigger: template.tags.length > 1 ? 'mouseenter' : 'manual', hideOnClick: false }}
                >
                  <WppTag label={getTagText(template.tags)} variant="neutral" className={styles.templateTags} />
                </WppTooltip>
              )}
            </>
          )}
        </UserInfo>
      </Flex>

      {fromCurrentTenant && withContextMenu && (
        <WppMenuContext slot="actions" data-testid="template-card-context-menu">
          <WppActionButton slot="trigger-element" variant="secondary">
            <WppIconMore slot="icon-start" direction="horizontal" />
          </WppActionButton>

          <WppListItem onWppChangeListItem={handleCreateFromTemplate} data-testid="create-project-option">
            <WppIconPlusCircle slot="left" />
            <WppTypography slot="label" type="s-body">
              {t('templates.card.create_from_template')}
            </WppTypography>
          </WppListItem>

          <WppListItem onWppChangeListItem={handleOpenTemplate} data-testid="view-template-option">
            <WppIconEyeOn slot="left" />
            <WppTypography slot="label" type="s-body">
              {t('templates.card.view_template')}
            </WppTypography>
          </WppListItem>

          {isOwnerOrGlobalManage && (
            <WppListItem onWppChangeListItem={handleTemplateEdit} data-testid="edit-template-option">
              <WppIconEdit slot="left" />
              <WppTypography slot="label" type="s-body">
                {t('templates.card.edit_template')}
              </WppTypography>
            </WppListItem>
          )}

          {/* TODO @v.novikov-star apply permissions: `Programme Manager / Tenant Admin / OS Admin role` */}
          {isOwnerOrGlobalManage && (
            <WppListItem onWppChangeListItem={handleRemoveTemplate} data-testid="context-remove-option">
              <WppIconTrash slot="left" />
              <WppTypography slot="label" type="s-body">
                {t('templates.card.remove')}
              </WppTypography>
            </WppListItem>
          )}
        </WppMenuContext>
      )}
    </WppCard>
  )
}
