import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { AttachmentMetadata, MayBeNull } from '@wpp-open/core'
import React, { useCallback, JSX } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { UpdatePresentation } from 'api/projects/fetchers/updateAppPresentationApi'
import { useCreateAppPresentationApi } from 'api/projects/mutation/useCreateAppPresentationApi'
import { useUpdateAppPresentationApi } from 'api/projects/mutation/useUpdateAppPresentationApi'
import { Flex } from 'components/common/flex/Flex'
import { FormFileUpload } from 'components/form/formFileUpload/FormFileUpload'
import { FormTextEditor } from 'components/form/formTextEditor/FormTextEditor'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useUploadProjectFilesApi } from 'hooks/attachments/useUploadProjectFilesApi'
import { useForm } from 'hooks/form/useForm'
import { useProjectAnalytics } from 'hooks/useProjectAnalytics'
import { useToast } from 'hooks/useToast'
import { ApplicationLogo } from 'pages/components/applicationLogo/ApplicationLogo'
import styles from 'pages/projectDemo/components/editAppPresentation/EditAppPresentationModal.module.scss'
import { PresentationFormValue, presentationToForm } from 'pages/projectDemo/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { Presentation, PresentationItemType } from 'types/projects/projectDemo'
import { Project } from 'types/projects/projects'
import { ApplicationItem } from 'types/projects/workflow'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { bytes, formatBytes } from 'utils/files'

interface Props extends NiceModalWrappedProps {
  project: Project
  application: ApplicationItem
  presentation: MayBeNull<Presentation>
}

const emptyValue: PresentationFormValue = { content: null, files: [] }

export const EditAppPresentationModal = ({
  project,
  application,
  presentation,
  isOpen,
  onClose,
  onCloseComplete,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const { trackProjectPresentationModeSlideEdit } = useProjectAnalytics({ project })

  const isNew = !presentation
  const form = useForm<PresentationFormValue>({
    defaultValues: isNew ? emptyValue : presentationToForm(presentation),
  })

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = form

  const fixLocalMsg = useCallback(
    (_: string, size: number) => `Only .jpg or .png file at ${formatBytes(bytes(size, 'mb'))} or less.`,
    [],
  )

  const handleUploadTenantsFiles = useUploadProjectFilesApi()
  const { mutateAsync: createAppPresentation } = useCreateAppPresentationApi()
  const { mutateAsync: updateAppPresentation } = useUpdateAppPresentationApi()

  const onSubmit = handleSubmit(async values => {
    try {
      const content = values.content ? JSON.stringify(values.content) : null
      let image: MayBeNull<AttachmentMetadata> = null

      if (values.files.length && values.files[0] instanceof File) {
        const resp = await handleUploadTenantsFiles(values.files as File[], project.id)
        image = resp[0]
      }

      if (isNew) {
        await createAppPresentation({
          projectId: project.id,
          itemId: application.id,
          subject: PresentationItemType.Application,
          content,
          image,
        })
      } else {
        let updateBody: UpdatePresentation = { id: presentation.id, content, image }
        // doesn't re-upload already existing file
        if (values.files.length && !(values.files[0] instanceof File)) {
          delete updateBody.image
        }
        await updateAppPresentation(updateBody)
      }

      trackProjectPresentationModeSlideEdit()

      await Promise.all([
        queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR]),
        queryClient.invalidateQueries([ApiQueryKeys.PROJECT_PRESENTATION]),
      ])

      onClose()
    } catch (e) {
      console.log(e)
      showToast({ type: 'error', message: t('project.demo.save_error')! })
    }
  })

  return (
    <FormProvider {...form}>
      <SideModal
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        size="l"
        data-testid="edit-demo-modal"
      >
        <WppTypography slot="header" type="2xl-heading" data-testid="edit-demo-modal-header-text">
          {t('project.demo.action.settings')}
        </WppTypography>
        <Flex slot="body" direction="column" gap={24}>
          <Flex className={styles.appInfo} gap={12}>
            <ApplicationLogo logo={application?.logoUrl} />
            <WppTypography type="l-midi">{application.name ?? application.externalAppName}</WppTypography>
          </Flex>

          <FormFileUpload name="files" size={50} maxLabelLength={45} locales={{ info: fixLocalMsg }} multiple={false} />

          <FormTextEditor
            name="content"
            labelConfig={{ text: t('project.demo.content') }}
            data-testid="edit-demo-modal-content"
          />
        </Flex>
        <Flex slot="actions" justify="end" gap={12}>
          <WppButton variant="secondary" size="m" onClick={onClose}>
            {t('common.btn_cancel')}
          </WppButton>
          <WppButton variant="primary" size="m" type="submit" loading={isSubmitting} disabled={!isValid}>
            {t('common.btn_save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showEditPresentationModal } = createNiceModal<Props>(
  EditAppPresentationModal,
  'edit-presentation-modal',
)
