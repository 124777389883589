import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { useProject } from 'hooks/useProject'
import { useProjectAnalytics } from 'hooks/useProjectAnalytics'
import { AppPresentation } from 'pages/projectDemo/components/appPresentation/AppPresentation'
import { ProjectInfo } from 'pages/projectDemo/components/projectInfo/ProjectInfo'
import { SideMenu } from 'pages/projectDemo/components/sideMenu/SideMenu'
import styles from 'pages/projectDemo/ProjectDemo.module.scss'
import { ProjectDemoSkeleton } from 'pages/projectDemo/ProjectDemoSkeleton'
import { DemoItemType, DemoState } from 'pages/projectDemo/utils'

export const ProjectDemo = () => {
  const { project, isProjectLoading, linearData, isLinearLoading } = useProject()
  const [params] = useSearchParams()
  const [demoState, setDemoState] = useState<DemoState>({ type: DemoItemType.ProjectInfo })
  const { trackProjectPresentationMode } = useProjectAnalytics({ project })

  const isProjectReady = !isProjectLoading && project
  const isCanvasReady = !isLinearLoading

  useEffect(() => {
    trackProjectPresentationMode()
  }, [trackProjectPresentationMode])

  if (!isProjectReady || !isCanvasReady) {
    return <ProjectDemoSkeleton />
  }

  return (
    <Flex className={styles.container} direction="column" data-testid="demo-page">
      <SideMenu
        linearData={linearData}
        demoState={demoState}
        setDemoState={setDemoState}
        project={project}
        selectedAppId={params.get('selectedAppId') || demoState.appId}
        selectedPhaseId={params.get('selectedPhaseId') || demoState.phaseId}
      />

      <div className={styles.infoSection}>
        {demoState.type === DemoItemType.ProjectInfo ? (
          <ProjectInfo project={project} />
        ) : (
          <AppPresentation project={project} appId={demoState.appId!} />
        )}
      </div>
    </Flex>
  )
}
